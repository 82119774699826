.header {
  box-shadow: inset 0px -1px 0px $gray-100;
}

.muze-hamburger {
  .menu-close {
    width: 20px;
  }
}

.dropdown-toggle {
  &.no-carret {
    &::after {
      content: none;
    }
  }
}

.muze-search {
  i {
    color: $black;
  }
}

.search-box {
  min-width: 140px;

  .input-group {
    padding: 0 10px;
    width: 140px;

    .form-control {
      height: 32px;
      padding: 2px 10px;
      font-size: 13px;
      line-height: 1;
      font-weight: 400;
      color: $dark-4;
    }
    .icon-serach{
      color: $dark-6;
    }
    img {
      height: 14px;
    }
  }
}
.search-box-open{
    overflow: hidden;
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: rgba(18,17,17,.6);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
    }
}
.header-body {
  padding: 12px 16px;
  @include media-breakpoint-up(lg) {
    padding: 14.21px 28px;
  }
  .header-search-box {
    @include media-breakpoint-up(md) {
      position: relative;
    }
    .form-control {
      height: 32px;
      padding: 2px 10px 2px 34px;
      font-size: 13px;
      line-height: 1;
      font-weight: 400;
      color: $dark-4;
      width: 120px;
      transition: all .5s;
      background-image: url(../../img/icons/serach.svg);
      background-repeat: no-repeat;
      background-position: left 10px center;
      background-size: 16px;
      @include media-breakpoint-down(md){
        width: 100%;
      }
      &:focus {
        width: 220px;
        @include media-breakpoint-down(md){
          width: 100%;
        }
      }
      
    }
    @include media-breakpoint-down(md){
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-color: $white;
      min-width: 100%;
      z-index: 9;
      padding: 0.9375rem;
      cursor: pointer;
      @at-root .search-box-open &{
        display: flex;
        align-items: center;
        z-index: 11;
      }
      @at-root .top-alert-notification &{
        padding: 28px 0.9375rem;
      }
    }
    .search-input{
      @include media-breakpoint-down(md){
        flex: 1;
      }
    }
    .search-close-mobile{
      
    }

    .search-result {
      box-shadow: 0px 8px 16px rgba(33, 30, 30, 0.16);
      border-radius: 0.714rem;
      padding: 15px 0;
      width: 100%;
      min-width: 300px;
      max-width: 300px;
      position: absolute;
      z-index: 1000;
      background-color: #fff;
      top: -10000px;
      display: block;
      opacity: 0;
      right: 0;
      transition: transform .2s ease-out;
      transform: translate3d(0, -50px, 0);
      transform: translate3d(0, 0, 0);
      top: auto;
      display: block;
      opacity: 1;

      @include media-breakpoint-up(lg) {
        max-width: 400px;
        min-width: 400px;
      }
      @include media-breakpoint-down(md){
        box-shadow: none;
        border-radius: 0;
        max-width: 100%;
        min-width: 100%;
      }

      .search-result-body {
        max-height: 300px;
        padding: 0 15px;
        @include media-breakpoint-down(md){
          max-height: calc(100vh - 200px);
        }
      }

      h5 {
        background-color: $dark-10;
        padding: 6px 10px;
        border-radius: 4px;
      }

      ul {
        list-style: none;
        padding-left: 0;

        li {
          a {
            padding: 8px 10px;
            display: block;
            color: $dark-1;
            border-radius: 4px;

            &:hover {
              background-color: $dark-11;

            }
          }
        }
      }
    }
  }
}

.profile-dropdown {
  .dropdown-menu {
    @include media-breakpoint-up(xl) {
      min-width: 300px;
    }

    .avatar-md {
      width: 2.857rem;
      height: 2.857rem;
      min-width: 2.857rem;
    }
  }
}

.notification-dropdown {
  .dropdown-menu {
    min-width: 320px;
    @include media-breakpoint-up(sm) {
      min-width: 520px;
    }

    .dropdown-body {
      padding: 0 10px;

      .dropdown-item {
        border-top: 0;
        padding: 0.714rem;
        border-radius: 0.429rem;
      }
    }
  }
}