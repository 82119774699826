@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon/icomoon.eot');
  src:  url('../../fonts/icomoon/icomoon.eot') format('embedded-opentype'),
    url('../../fonts/icomoon/icomoon.ttf') format('truetype'),
    url('../../fonts/icomoon/icomoon.woff') format('woff'),
    url('../../fonts/icomoon/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}


[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-star-fill:before {
  content: "\e906";
}
.icon-board:before {
  content: "\e907";
}
.icon-add:before {
  content: "\e908";
}
.icon-arrow:before {
  content: "\e909";
}
.icon-attachment:before {
  content: "\e90a";
}
.icon-bold:before {
  content: "\e90b";
}
.icon-calendar:before {
  content: "\e90c";
}
.icon-calendar-list:before {
  content: "\e90d";
}
.icon-camera:before {
  content: "\e90e";
}
.icon-cancel:before {
  content: "\e90f";
}
.icon-delete:before {
  content: "\e910";
}
.icon-department:before {
  content: "\e911";
}
.icon-download:before {
  content: "\e912";
}
.icon-dropdown-fill:before {
  content: "\e913";
}
.icon-edit:before {
  content: "\e914";
}
.icon-edit-icon:before {
  content: "\e915";
}
.icon-emoji:before {
  content: "\e916";
}
.icon-filter:before {
  content: "\e917";
}
.icon-frame-59:before {
  content: "\e918";
}
.icon-home:before {
  content: "\e919";
}
.icon-italic:before {
  content: "\e91a";
}
.icon-link:before {
  content: "\e91b";
}
.icon-list:before {
  content: "\e91c";
}
.icon-location:before {
  content: "\e91d";
}
.icon-message:before {
  content: "\e91e";
}
.icon-more:before {
  content: "\e91f";
}
.icon-move:before {
  content: "\e920";
}
.icon-notificatons:before {
  content: "\e921";
}
.icon-organization:before {
  content: "\e922";
}
.icon-report:before {
  content: "\e923";
}
.icon-send:before {
  content: "\e924";
}
.icon-serach:before {
  content: "\e925";
}
.icon-sub-task:before {
  content: "\e926";
}
.icon-tag:before {
  content: "\e927";
}
.icon-task:before {
  content: "\e928";
}
.icon-time:before {
  content: "\e929";
}
.icon-tropy:before {
  content: "\e92a";
}
.icon-underline:before {
  content: "\e92b";
}
.icon-user-1:before {
  content: "\e92c";
}
.icon-chalkboard-user:before {
  content: "\e92d";
}
.icon-clipboard-list-check:before {
  content: "\e92e";
}
.icon-face-mask:before {
  content: "\e92f";
}
.icon-person-to-door:before {
  content: "\e930";
}
.icon-receipt:before {
  content: "\e931";
}
.icon-lock:before {
  content: "\e932";
}
.icon-factor:before {
  content: "\e933";
}
.icon-right-check:before {
  content: "\e934";
}
.icon-close1:before {
  content: "\e935";
}
.icon-user-role:before {
  content: "\e905";
}
.icon-users:before {
  content: "\e904";
}
.icon-star-line:before {
  content: "\e903";
}
.icon-listing:before {
  content: "\e902";
}
.icon-bookmark:before {
  content: "\e901";
}
.icon-add-on:before {
  content: "\e900";
}
.icon-badge-check:before {
  content: "\e936";
}
.icon-star:before {
  content: "\e937";
}
.icon-file-excel:before {
  content: "\e938";
}
.icon-chevron-up:before {
  content: "\e939";
}
.icon-chevron-down:before {
  content: "\e93a";
}
.icon-chevron-left:before {
  content: "\e93b";
}
.icon-chevron-right:before {
  content: "\e93c";
}
.icon-rotate-right:before {
  content: "\e93d";
}
.icon-user-tag:before {
  content: "\e93e";
}
.icon-user-xmark:before {
  content: "\e93f";
}