.btn{
    &.circle-btn{
        border-radius: 50%;
    }
}
.btn-icon{
    width: 2rem;
    height: 2rem;
    min-width: 2rem;
    min-height: 2rem;
    padding: 2px;
    font-size: 1.714rem;
    line-height: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        width: 12px;
        height: 12px;
        &.img-md{
            width: 16px;
            height: 16px;
        }
    }
    i{
        font-size: 1rem;
    }
    &.btn-icon-lg{
        width: 3rem;
        height: 3rem;
        img{
            width: 18px;
            height: 18px;
            &.img-md{
                width: 16px;
                height: 16px;
            }
        }
        i{
            font-size: 1.2rem;
        }
    }
}
.btn-white{
    background-color: transparent;
    color: $dark-5;
    &:hover, &:focus, &:active{
        background-color: $dark-10;
        color: $dark-5;
    }
}


.btn-icon-red{
    background-color: transparent;
    color: $red1;
    &:hover, &:focus, &:active{
        background-color: rgba($color: $red1, $alpha: 0.2);
        color: $red1;
    }
}

.btn-icon-green{
    background-color: transparent;
    color: $green;
    &:hover, &:focus, &:active{
        background-color: rgba($color:$green, $alpha: 0.2);
        color: $green;
    }
}

.btn-icon-primary{
    background-color: transparent;
    color: $primary;
    &:hover, &:focus, &:active{
        background-color: rgba($color:$primary, $alpha: 0.2);
        color: $primary;
    }
}
.btn-icon-secondary{
    background-color: transparent;
    color: $secondary;
    &:hover, &:focus, &:active{
        background-color: rgba($color:$secondary, $alpha: 0.2);
        color: $secondary;
    }
}

.fc{
    &.fc-media-screen{
        .fc-button{
            text-decoration: none;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 6px 12px;
            font-size: 12px;
            font-weight: 400;
            height: 28px;
            border-radius: 0;
            text-transform: capitalize;
            @include media-breakpoint-down(sm) {
                height: 24px;
                padding: 5px 8px;
                font-size: 12px;
            }
        }
        
        .fc-button-primary {
            background-color: transparent;
            border-color: transparent;
            color: $dark-5;
            &:not(:disabled){
                &:active, &.fc-button-active, &:hover{
                    background-color: transparent;
                    border-color: $dark-5;
                    color: $dark-5;
                }
            }
        }
    }
} 
